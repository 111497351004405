import { render, staticRenderFns } from "./IdUpload.vue?vue&type=template&id=cfdc0a88&scoped=true&"
import script from "./IdUpload.vue?vue&type=script&lang=js&"
export * from "./IdUpload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfdc0a88",
  null
  
)

export default component.exports