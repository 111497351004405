<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="פרטים אישיים" :total-steps="6" :current-step="1"/>
      <div class="ri-large-headline">פרטים אישיים</div>
      <div class="ri-large-body margin-bottom-m">זה מה שמופיע אצלנו, כדאי לוודא שהכל נכון</div>
      <text-input class="margin-top-l" label-text="שם פרטי*" autocomplete="given-name"
                  v-model="firstName" :onInput="setFirstName" :error-message="firstNameError" />
      <text-input class="margin-top-m" label-text="שם משפחה*" autocomplete="family-name"
                  v-model="lastName" :onInput="setLastName" :error-message="lastNameError"/>
      <text-input class="margin-top-m" label-text="טלפון נייד*" autocomplete="mobile tel" v-model="phoneNumber" :onInput="setPhoneNumber" disabled/>
      <email-input class="margin-top-m" label-text="אימייל*"
                   autocomplete="email"
                   :value="email"
                   :onInput="setEmailAddress"
                   :onError="setEmailError"/>
    </div>
  </sticky-cta-page>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapState } from 'vuex';
import phoneNumberUtils from '@/utils/phoneNumber';
import FlowProgressHeader
  from '@/pages/responsive-pages/authenticated/navigation-pages/xnes/join/FlowProgressHeader.vue';

const hebrewRegex = /^[\u0590-\u05FF-'\s]*$/;

export default {
  name: 'PersonalInfo',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    TextInput: BaseUI.V2Components.TextInput,
    EmailInput: BaseUI.V2Components.EmailInput,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstName: undefined,
      firstNameError: '',
      lastName: undefined,
      lastNameError: '',
      phoneNumber: undefined,
      email: undefined,
      emailError: undefined,
      initialValues: {},
    };
  },
  created() {
    this.firstName = this.wizardState.firstNamePiiValue ?? this.activeMember.firstNamePiiValue ?? '';
    this.setFirstName(this.firstName);
    this.lastName = this.wizardState.lastNamePiiValue ?? this.activeMember.lastNamePiiValue ?? '';
    this.setLastName(this.lastName);
    this.phoneNumber = this.wizardState.phoneNumberPiiValue ?? phoneNumberUtils.formatPhoneNumber(this.activeMember.phoneNumberPiiValue) ?? '';
    this.email = this.wizardState.emailPiiValue ?? this.activeMember.emailPiiValue ?? '';
    this.setEmailAddress(this.email);
    this.initialValues = {
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
      email: this.email,
    };
  },
  computed: {
    ...mapState('session', ['activeMember']),
    nextDisabled() {
      const nameNotHebrew = !hebrewRegex.test(this.firstName) || !hebrewRegex.test(this.lastName);
      return nameNotHebrew
        || this.firstName.length < 2
        || this.lastName.length < 2
        || _.isEmpty(this.phoneNumber)
        || _.isEmpty(this.email)
        || this.emailError;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setFirstName(value) {
      if (!hebrewRegex.test(value)) {
        this.firstNameError = 'עברית בלבד';
      } else {
        this.firstNameError = '';
      }
    },
    setLastName(value) {
      if (!hebrewRegex.test(value)) {
        this.lastNameError = 'עברית בלבד';
      } else {
        this.lastNameError = '';
      }
    },
    setPhoneNumber(value) {
      this.phoneNumber = value;
    },
    setEmailAddress(value) {
      this.emailError = undefined;
      this.email = value;
    },
    setEmailError(value) {
      if (_.isEmpty(this.email)) {
        return;
      }
      this.emailError = value;
    },
    nextStep() {
      const personalInfo = {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
      };
      Segment.trackUserInteraction('Altshuler_PersonalInfo_NextClicked',
        { edited: !_.isEqual(personalInfo, this.initialValues) });
      this.$emit('next', personalInfo);
    },
  },
};
</script>
