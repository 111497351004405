<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="הצהרות: מצב תעסוקתי" :total-steps="6" :current-step="5"/>
      <div class="ri-large-headline">מה מקור הכספים של ההפקדות הראשונות שיופקדו בחשבון המסחר?</div>
      <div class="ri-large-body margin-bottom-m">אפשר לבחור יותר ממקור אחד</div>

      <check-box v-model="depositSource.paycheck" :initially-checked="depositSource.paycheck"
                 label="משכורות" />
      <check-box v-model="depositSource.unemploymentBenefits" :initially-checked="depositSource.unemploymentBenefits"
                 label="דמי אבטלה" />
      <check-box v-model="depositSource.compensations" :initially-checked="depositSource.compensations"
                 label="פיצויים מעבודה או מחברת ביטוח" />
      <check-box v-model="depositSource.gift" :initially-checked="depositSource.gift"
                 label="מתנות מאירועים" />
      <check-box v-model="depositSource.giftFromParents" :initially-checked="depositSource.giftFromParents"
                 label="מתנה או ירושה מההורים או סבים" />
      <check-box v-model="depositSource.giftFromFamily" :initially-checked="depositSource.giftFromFamily"
                 label="מתנה או ירושה מקרוב משפחה אחר" />
      <check-box v-model="depositSource.giftFromOthers" :initially-checked="depositSource.giftFromOthers"
                 label="מתנה או ירושה לא מקרובי משפחה" />
      <check-box v-model="depositSource.allowance" :initially-checked="depositSource.allowance"
                 label="קצבה / גמלה / קרן השתלמות" />
      <check-box v-model="depositSource.ira" :initially-checked="depositSource.ira"
                 label="קופת גמל / IRA" />
      <check-box v-model="depositSource.pensions" :initially-checked="depositSource.pensions"
                 label="חסכונות פנסיוניים" />
      <check-box v-model="depositSource.privateRealEstate" :initially-checked="depositSource.privateRealEstate"
                 label="מכירת או השכרת נדל״ן פרטי" />
      <check-box v-model="depositSource.businessRealEstate" :initially-checked="depositSource.businessRealEstate"
                 label="מכירת או השכרת נדל״ן עסקי" />
      <check-box v-model="depositSource.suspiciousActivity" :initially-checked="depositSource.suspiciousActivity" alignment="top"
                 label="משכורות מעיסוק קודם באחד מהבאים:
סחר ביהלומים/מתכות/שירותי מטבע/מכירת אומנות/סחר בכלי תחבורה/הימורים/ייבוא ייצוא" />
      <check-box v-model="depositSource.previousBusinessIncome" :initially-checked="depositSource.previousBusinessIncome"
                 label="הכנסות מעסק או דיבדבנדים" />
      <check-box v-model="depositSource.winning" :initially-checked="depositSource.winning"
                 label="זכייה" />
      <check-box v-model="depositSource.other" :initially-checked="depositSource.other"
                 label="אחר" />
      </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'MoneySource',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    CheckBox: BaseUI.CheckBox,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      depositSource: {
        paycheck: false,
        unemploymentBenefits: false,
        compensations: false,
        gift: false,
        giftFromParents: false,
        giftFromFamily: false,
        giftFromOthers: false,
        allowance: false,
        ira: false,
        pensions: false,
        privateRealEstate: false,
        businessRealEstate: false,
        suspiciousActivity: false,
        previousBusinessIncome: false,
        winning: false,
        other: false,
      },
    };
  },
  created() {
    if (!_.isUndefined(this.wizardState.depositSource)) {
      this.depositSource = this.wizardState.depositSource;
    }
  },
  computed: {
    nextDisabled() {
      return _.chain(this.depositSource).values().sumBy(item => (!item ? 0 : 1)).value() < 1;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    nextStep() {
      Segment.trackUserInteraction('Xnes_Employment_NextClicked');
      // this.$emit('next', this.employment);
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">

</style>
