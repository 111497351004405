<template>
  <sticky-cta-page :cta-props="pageCtaProps" :page-background-color="pageBackgroundColor" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs page">
      <div class="ri-small-display margin-top-xxl">
        <div>זוהר, כמעט</div>
        <div>סיימת!</div>
      </div>
      <div class="margin-top-l flex-1 flex-row padding-horizontal-m">
        <div class="page-progress-bar"></div>
        <div class="flex-1 flex-column">
          <div class="margin-right-s page-progress-item">
            פרטים אישיים
          </div>
          <div class="margin-top-ml page-progress-item">
            אזרחות ותושבות מס
          </div>
          <div class="margin-top-ml page-progress-item">
            תעודה מזהה
          </div>
          <div class="margin-top-ml page-progress-item">
            חשבון בנק
          </div>
          <div class="margin-top-ml page-progress-item">
            הצהרות
          </div>
          <div class="margin-top-ml page-progress-item">
            אישורים וחתימה
          </div>
          <div class="page-progress-item-last">
            <div class="days-for">תוך x ימים</div>
            <div class="goal">פתיחת חשבון מסחר!</div>
          </div>
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>

import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ProgressPage',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pageBackgroundColor: BaseUI.Colors.riseupLightBlue,
    };
  },
  created() {

  },
  computed: {
    pageCtaProps() {
      return {
        ...this.ctaProps,
        backgroundColor: BaseUI.Colors.riseupLightBlue,
        buttonProps: {
          ...this.ctaProps.buttonProps,
          disabled: this.nextDisabled,
        },
      };
    },
  },
  methods: {
    nextStep() {
      Segment.trackUserInteraction('Xnes_ProgressPage_NextClicked');
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/typography';

.page {
  background-color: $riseup_light_blue;
  background-image: url(~@/assets/xnes/xnes-progress-page.svg);
  background-repeat: no-repeat;
  background-size: 255px 255px;
  background-position: top 30px left 0;

  .page-progress-bar {
    width: 40px;
    background-image: url(~@/assets/xnes/vertical-progress.svg);
    background-repeat: no-repeat;
  }

  .page-progress-item {
    height: 38px;
    display: flex;
    align-items: center;
    margin-right: $ri-spacing-s;
    color: $riseup_dark_blue;
    font-family: SimplerPro, sans-serif;
    font-size: $ri-font-size-20;
    line-height: $ri-font-size-26;
    font-weight: $ri-font-weight-bold;
  }

  .page-progress-item-last {
    height: 38px;
    display: flex;
    flex-direction: column;
    margin-top: 55px;

    .days-for {
      display: flex;
      align-items: center;
      margin-right: $ri-spacing-s;
      color: $riseup_dark_blue;
      font-family: SimplerPro, sans-serif;
      font-size: $ri-font-size-14;
      font-weight: $ri-font-weight-bold;
    }
    .goal {
      display: flex;
      align-items: center;
      margin-right: $ri-spacing-s;
      color: $riseup_danger_red;
      font-family: SimplerPro, sans-serif;
      font-size: $ri-font-size-20;
      line-height: $ri-font-size-26;
      font-weight: $ri-font-weight-bold;
    }

  }
}
</style>
