<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="הצהרות: מצב תעסוקתי" :total-steps="6" :current-step="5"/>
      <div class="ri-large-headline">בגלל שבחרת ״שכיר.ה״</div>

      <text-input class="margin-top-l" label-text="מה התפקיד הנוכחי שלך?*"
                  v-model="jobDescription" :onInput="setJobDescription" :error-message="jobDescriptionError" />
      <text-input class="margin-top-l" label-text="באיזו חברה?*"
                  v-model="company" :onInput="setCompany" :error-message="companyError" />

      <div class="margin-bottom-s">מה הותק שלך שם?*</div>
      <radio-button unique-key="seniority" :items="seniorityValues" @change="setSelectedSeniority" />

    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import RadioButton from '@/pages/responsive-pages/authenticated/navigation-pages/xnes/components/RadioButton.vue';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'Employed',
  components: {
    RadioButton,
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    TextInput: BaseUI.V2Components.TextInput,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      jobDescription: undefined,
      jobDescriptionError: '',
      company: undefined,
      companyError: '',
      seniority: undefined,
      seniorityValues: [
        { value: 's0', label: 'עד 5 שנים' },
        { value: 's1', label: 'בין 6 ל-10 שנים' },
        { value: 's2', label: 'מעל 11 שנים' },
      ],
    };
  },
  created() {
    this.jobDescription = this.wizardState.jobDescription ?? '';
    this.company = this.wizardState.company ?? '';
  },
  computed: {
    nextDisabled() {
      return this.jobDescription.length < 2
        || this.company.length < 2
        || _.isUndefined(this.seniority);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setJobDescription(value) {
      this.jobDescription = value;
    },
    setCompany(value) {
      this.company = value;
    },
    setSelectedSeniority(value) {
      this.seniority = value;
    },
    nextStep() {
      Segment.trackUserInteraction('Xnes_Employmed_NextClicked');
      // this.$emit('next', this.employment);
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">

</style>
