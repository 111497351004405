<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="הצהרות: איש או אשת ציבור" :total-steps="6" :current-step="5"/>
      <div class="ri-large-headline">איש או אשת ציבור</div>
      <div class="ri-large-bold-body margin-top-m">דוגמאות:</div>
      <div class="ri-large-body margin-bottom-xl">נשיא מדינה, ראשת מדינה, חבר מפלגה בכיר, ראשת עיר/רשות מקומית, חברת פרלמנט, חבר ממשלה,
        קצין צבא או משטרה בכיר מדרגה תת אלוף/תת ניצב, נושאת משרה בכירה בחברות ממשלתיות, בעל תפקיד בארגונים
        בין-לאומיים - או כל תפקיד דומה לאלו, אבל עם תואר שונה. </div>

      <div class="ri-large-body margin-bottom-s">האם היית בעבר או הינך כעת, אישיות ציבורית?</div>
      <div class="flex-row small-buttons">
        <selection-button @select="setPublicFigure(false)"
                          :selected="publicFigure === false"
                          title="לא"
                          :icon-props="{ iconName: 'close', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-left-xs"/>
        <selection-button @select="setPublicFigure(true)"
                          :selected="!!publicFigure"
                          title="כן"
                          :icon-props="{ iconName: 'check', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-right-xs"/>
      </div>

      <div class="ri-large-body margin-bottom-s margin-top-m">האם יש לך בן משפחה/שותף עסקי/נהנה בחשבונך, שהוא אישיות ציבורית?</div>
      <div class="flex-row small-buttons">
        <selection-button @select="setPartnerPublicFigure(false)"
                          :selected="partnerPublicFigure === false"
                          title="לא"
                          :icon-props="{ iconName: 'close', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-left-xs"/>
        <selection-button @select="setPartnerPublicFigure(true)"
                          :selected="!!partnerPublicFigure"
                          title="כן"
                          :icon-props="{ iconName: 'check', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-right-xs"/>
      </div>

    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'PublicFigure',
  components: {

    SelectionButton: BaseUI.SelectionButton,
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      publicFigure: undefined,
      partnerPublicFigure: undefined,
    };
  },
  created() {
    this.publicFigure = this.wizardState.publicFigure ?? undefined;
    this.partnerPublicFigure = this.wizardState.partnerPublicFigure ?? undefined;
  },
  computed: {
    nextDisabled() {
      return _.isUndefined(this.publicFigure) || _.isUndefined(this.partnerPublicFigure);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setPublicFigure(value) {
      this.publicFigure = value;
    },
    setPartnerPublicFigure(value) {
      this.partnerPublicFigure = value;
    },
    nextStep() {
      Segment.trackUserInteraction('Xnes_Income_NextClicked');
      // this.$emit('next', this.incomeValue);
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">

.small-buttons {
  width: 80%;
}
</style>
