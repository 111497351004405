<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="פרטים אישיים" :total-steps="6" :current-step="1"/>
      <div class="ri-large-headline">כתובת מגורים</div>
      <div class="ri-large-body margin-bottom-m">בשביל אימות התעודה המזהה שתועלה בהמשך</div>

      <text-input class="margin-top-l" label-text="עיר*" autocomplete="city"
                  v-model="city" :onInput="setCity" :error-message="cityError" />
      <text-input class="margin-top-m" label-text="רחוב*" autocomplete="street"
                  v-model="street" :onInput="setStreet" :error-message="streetError"/>
      <div class="flex-row">
        <text-input class="margin-top-m" label-text="מספר בית*" autocomplete="houseNumber"
                    v-model="houseNumber" :onInput="setHouseNumber" :error-message="houseNumberError"/>
        <div class="full-width flex-row">
          <text-input class="margin-top-m padding-right-xs" label-text="דירה" autocomplete="apartment"
                      v-model="aptNumber" :onInput="setAptNumber" :error-message="aptNumberError"/>
          <text-input class="margin-top-m padding-right-xs" label-text="כניסה" autocomplete="entrance"
                      v-model="entrance" :onInput="setEntrance" :error-message="entranceError"/>
        </div>
      </div>

      <div class="flex-row align-center">
        <text-input class="margin-top-m" label-text="מיקוד*" autocomplete="zip-code"
                    v-model="zipCode" :onInput="setZipCode" :error-message="zipCodeError"/>
        <div class="full-width link" @click="findZipCode">מה המיקוד שלי?</div>
      </div>

    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'MorePersonalInfo',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    TextInput: BaseUI.V2Components.TextInput,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      city: undefined,
      cityError: '',
      street: undefined,
      streetError: '',
      houseNumber: undefined,
      houseNumberError: '',
      aptNumber: undefined,
      aptNumberError: '',
      entrance: undefined,
      entranceError: '',
      zipCode: undefined,
      zipCodeError: '',
    };
  },
  created() {
    this.city = this.wizardState.city ?? '';
    this.setCity(this.city);
    this.street = this.wizardState.street ?? '';
    this.setStreet(this.street);
    this.houseNumber = String(this.wizardState.houseNumber ?? '');
    this.setHouseNumber(this.houseNumber);
    this.aptNumber = String(this.wizardState.aptNumber ?? '');
    this.setAptNumber(this.aptNumber);
    this.entrance = this.wizardState.entrance ?? '';
    this.setEntrance(this.entrance);
    this.zipCode = this.wizardState.zipCodePiiValue ?? '';
    this.setZipCode(this.zipCode);
  },
  computed: {
    nextDisabled() {
      return !!this.cityError || _.isEmpty(this.city)
          || !!this.streetError || _.isEmpty(this.street)
          || !!this.houseNumberError || _.isEmpty(this.houseNumber)
          || !!this.aptNumberError
          || !!this.entranceError
          || !!this.zipCodeError || _.isEmpty(this.zipCode);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setCity(value) {
      if (false && true && value) {
        this.cityError = 'באנגלית בלבד';
      } else {
        this.cityError = '';
      }
    },
    setStreet(value) {
      if (false && true && value) {
        this.streetError = 'באנגלית בלבד';
      } else {
        this.streetError = '';
      }
    },
    setHouseNumber(value) {
      if (false && true && value) {
        this.houseNumberError = 'באנגלית בלבד';
      } else {
        this.houseNumberError = '';
      }
    },
    setAptNumber(value) {
      if (false && true && value) {
        this.aptNumberError = 'באנגלית בלבד';
      } else {
        this.aptNumberError = '';
      }
    },
    setEntrance(value) {
      if (false && true && value) {
        this.entranceError = 'באנגלית בלבד';
      } else {
        this.entranceError = '';
      }
    },
    setZipCode(value) {
      if (false && true && value) {
        this.zipCodeError = 'באנגלית בלבד';
      } else {
        this.zipCodeError = '';
      }
    },
    findZipCode() {
      window.open(
        'https://israelpost.co.il/%D7%A9%D7%99%D7%A8%D7%95%D7%AA%D7%99%D7%9D/%D7%90%D7%99%D7%AA%D7%95%D7%A8-%D7%9E%D7%99%D7%A7%D7%95%D7%93/',
        '_blank',
      );
    },
    nextStep() {
      const info = {
        city: this.city,
        street: this.street,
        houseNumber: this.houseNumber,
        aptNumber: this.aptNumber,
        entrance: this.entrance,
        zipCode: this.zipCode,
      };
      Segment.trackUserInteraction('Xnes_Address_NextClicked');
      this.$emit('next', info);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.link {
  cursor: pointer;
  text-align: center;
  color: $riseup_blue;
  text-decoration: underline;
}
</style>
