<template>
  <header-page :sticky-header="stickyHeader" :header-props="headerProps" v-if="!submitting && !error && !progressStep" ref="headerPage">
    <component :is="step.step" :cta-props="ctaProps" :current-step-index="currentStepIndex" v-if="wizardState"
               :wizard-state="wizardState" :total-steps="numberOfSteps" @next="nextStep" @submit="submit" @close="close"/>
  </header-page>
  <component v-else-if="progressStep" :is="step.step" :cta-props="ctaProps" :current-step-index="currentStepIndex"
             :total-steps="numberOfSteps" @next="nextStep" @submit="submit" @close="close"/>
  <div v-else-if="!error" class="full-width flex-column align-center justify-content-center">
    <lottie-player src="/animations/new-brand-loader-blue-variation.json" autoplay loop
                   class="margin-vertical-l" preserveAspectRatio="xMidYMax slice"/>
    <div class="flex-column align-center">
      <div class="ri-bold-title">הבקשה לפיקדון נשלחת</div>
      <div class="ri-body">זה ייקח כמה רגעים...</div>
    </div>
  </div>
  <error-page v-else :error-type="error"/>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import DDLogs from '@/DDLogs';
import router from '@/router';
import Segment from '@/Segment';
import * as XnesApi from '@/api/XnesApi';
import PersonalInfo from './PersonalInfo.vue';
import MorePersonalInfo from './MorePersonalInfo.vue';
import Address from './Address.vue';
import Citizenship from './Citizenship.vue';
import PayingTaxes from './PayingTaxes.vue';
import IdTypeSelection from './IdTypeSelection.vue';
import IdUpload from './IdUpload.vue';
import SelectBankAccount from './SelectBankAccount.vue';
import ProgressPage from './ProgressPage.vue';
import Employment from './Employment.vue';
import Income from './Income.vue';
import Employed from './Employed.vue';
import Activities from './Activities.vue';
import Statements from './Statements.vue';
import PublicFigure from './PublicFigure.vue';
import SourceOfMoney from './SourceOfMoney.vue';
import ErrorPage from './ErrorPage.vue';

const TOTAL_STEPS = 6;
const STEPS = [
  { step: PersonalInfo, index: 1 },
  { step: MorePersonalInfo, index: 1 },
  { step: Address, index: 1 },
  { step: Citizenship, index: 2 },
  { step: PayingTaxes, index: 2 },
  { step: IdTypeSelection, index: 3 },
  { step: IdUpload, index: 3 },
  { step: SelectBankAccount, index: 4 },
  { step: ProgressPage, index: 4.5 },
  { step: Employment, index: 5 },
  { step: Income, index: 5 },
  { step: Employed, index: 5 },
  { step: Activities, index: 5 },
  { step: Statements, index: 5 },
  { step: PublicFigure, index: 5 },
  { step: SourceOfMoney, index: 5 },
];

export default {
  name: 'XnesJoinFlow',
  components: {
    ErrorPage,
    HeaderPage: BaseUI.Pages.HeaderPage,
  },
  data() {
    return {
      currentStepIndex: 0,
      wizardState: null,
      loading: false,
      submitting: false,
      error: '',
    };
  },
  async created() {
    this.loading = true;
    Segment.trackUserInteraction('Xnes_JoinFlow_Entered');
    this.loadAllCities().catch(err => DDLogs.info('Failed to load cities in xnes flow', err));
    const wizardState = await XnesApi.getWizardState();
    if (!wizardState) {
      await XnesApi.createWizardState();
      this.wizardState = {};
    } else {
      this.wizardState = wizardState;
    }
    this.loading = false;
    this.initBalance();
  },
  methods: {
    ...mapActions('cities', ['loadAllCities']),
    ...mapActions('balance', ['initBalance']),
    async nextStep(updatedInfo) {
      this.loading = true;
      const newState = { ...this.wizardState, ...updatedInfo };
      this.wizardState = newState;
      await this.updateWizardState(newState);
      if (this.currentStepIndex < STEPS.length - 1) {
        ++this.currentStepIndex;
      }
      this.loading = false;
      this.scrollToTop();
    },
    scrollToTop() {
      this.$nextTick(() => {
        if (this.$refs.headerPage) {
          this.$refs.headerPage.resetScroll();
        }
      });
    },
    async submit() {
      Segment.trackUserInteraction('Xnes_JoinFlow_Submit');
      this.submitting = true;
      try {
        // await AltshulerApi.submitRequest();
        Segment.trackUserInteraction('Xnes_JoinFlow_SubmitSuccess');
      } catch (e) {
        if (e.message === 'Network Error' || (e.response && e.response.status === 0)) {
          this.error = 'connection';
        } else {
          this.error = 'data';
        }
        Segment.trackUserInteraction('Xnes_JoinFlowt_SubmitError', { error: this.error });
      }
      if (this.currentStepIndex < STEPS.length - 1) {
        ++this.currentStepIndex;
      }
      this.submitting = false;
    },
    prevStep() {
      Segment.trackUserInteraction('Xnes_JoinFlow_BackClicked',
        { stepIndex: this.currentStepIndex, stepName: STEPS[this.currentStepIndex].name });
      if (this.currentStepIndex > 0) {
        --this.currentStepIndex;
      } else {
        router.push({ path: '/xnes' });
      }
    },
    close() {
      Segment.trackUserInteraction('Xnes_JoinFlow_CloseClicked',
        { stepIndex: this.currentStepIndex, stepName: STEPS[this.currentStepIndex].name });
      router.push({ path: this.currentStepIndex === 8 ? '/sr' : '/xnes' });
    },
    async updateWizardState(newState) {
      await XnesApi.upsertWizardState(newState);
      this.wizardState = await XnesApi.getWizardState();
    },
  },
  computed: {
    step() {
      return STEPS[this.currentStepIndex];
    },
    progressStep() {
      return STEPS[this.currentStepIndex].step === ProgressPage;
    },
    numberOfSteps() {
      return TOTAL_STEPS;
    },
    prevDisabled() {
      return this.currentStepIndex === 0;
    },
    stickyHeader() {
      // maybe sometimes we don't want to show the header
      return true;
    },
    headerProps() {
      return {
        stepperProps: {
          currentStep: this.step.index,
          totalSteps: TOTAL_STEPS,
        },
        leftActions: [
          {
            type: BaseUI.HeaderActions.CLOSE,
            onClick: this.close,
          },
        ],
        rightActions: this.currentStepIndex > 0 && this.currentStepIndex < (STEPS.length - 1) ? [
          {
            type: BaseUI.HeaderActions.BACK,
            onClick: this.prevStep,
            disabled: this.prevDisabled,
          },
        ] : [],
      };
    },
    ctaProps() {
      return {
        buttonProps: {
          title: 'הבא >',
          loading: this.loading,
        },
      };
    },
  },
};
</script>

<style scoped lang="scss">
</style>
