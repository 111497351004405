import { render, staticRenderFns } from "./SourceOfMoney.vue?vue&type=template&id=f807ea6e&scoped=true&"
import script from "./SourceOfMoney.vue?vue&type=script&lang=js&"
export * from "./SourceOfMoney.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f807ea6e",
  null
  
)

export default component.exports