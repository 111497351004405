<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="הצהרות: עיסוקים נוספים וסטטוס" :total-steps="6" :current-step="5"/>
      <div class="ri-large-headline">עיסוק באחת או יותר מהפעילויות הבאות?</div>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="activities.none"
                   label="לא עוסק.ת באף אחת מהפעילויות">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="activities.art"
                   alignment="top"
                   label="מכירת אומנות | עיסוק בייבוא או ייצוא של מוצרים | פעילויות שונות בתחום ההימורים">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="activities.highProfile"
                   alignment="top"
                   label="אני בתפקיד בכיר באחד מהבאים
תעשיות ביטחוניות | עמותה/מלכ״ר | בכנסת/ממשלה | עירייה/רשות מקומית/מועצה אזורית או עובד.ת
בשביל בכיר בעירייה/רשות מקומית/מועצה אזורית/בכיר בכנסת/ממשלה">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="activities.stockMarket"
                   label="אני עובד.ת גמ״חים / בחבר בורסה ">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="activities.trustee"
                   alignment="top"
                   label="אני משמש.ת כנאמן עבור אחרים | עיסוק בתיווך עסקאות מיזוג חברות | אחראי.ת על קבלת החלטות לעניין מכרזים">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="activities.cash"
                   alignment="top"
                   label="מזומן
אני עובד.ת עם מזומנים או בעל.ת עסק עם ריבוי מזומנים (מעל 10 אלף בחודש בממוצע) ">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="activities.trade"
                   alignment="top"
                   label="סחר
סחר בכלי תחבורה | עיסוק בפעילות גז/נפט | פעילויות בתחום מטבעות דיגיטליים | סחר ביהלומים / סחר באבנים יקרות / מתכות / החלפת כספים / שירותי מטבע ">
        </check-box>
      </card>
    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'Activities',
  components: {
    Card: BaseUI.Card,
    CheckBox: BaseUI.CheckBox,
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activities: {
        none: false,
        art: false,
        highProfile: false,
        stockMarket: false,
        trustee: false,
        cash: false,
        trade: false,
      },
    };
  },
  created() {
    if (!_.isUndefined(this.wizardState.activities)) {
      this.activities = this.wizardState.activities;
    }
  },
  computed: {
    nextDisabled() {
      return _.chain(this.activities).values().sumBy(item => (!item ? 0 : 1)).value() < 1;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    nextStep() {
      Segment.trackUserInteraction('Xnes_Income_NextClicked');
      // this.$emit('next', this.incomeValue);
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">

</style>
