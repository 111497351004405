<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="פרטים אישיים" :total-steps="6" :current-step="1"/>
      <div class="ri-large-headline">עוד פרטים שצריך</div>
      <div class="ri-large-body margin-bottom-m">בשביל אימות התעודה המזהה שתועלה בהמשך</div>

      <selection-button @select="setGender('female')"
                        :selected="gender === 'female'"
                        title="נקבה"
                        :icon-props="{ iconName: 'female', fill: true, weight: 'bold' }"
                        class="margin-bottom-s"/>
      <selection-button @select="setGender('male')"
                        :selected="gender === 'male'"
                        title="זכר"
                        :icon-props="{ iconName: 'male', fill: true, weight: 'bold' }"
                        class="margin-bottom-s"/>

      <text-input class="margin-top-xxl" label-text="שם פרטי באנגלית*" autocomplete="given-name"
                  v-model="firstNameEnglish" :onInput="setFirstNameEnglish" :error-message="firstNameEnglishError" />
      <text-input class="margin-top-m" label-text="שם משפחה באנגלית*" autocomplete="family-name"
                  v-model="lastNameEnglish" :onInput="setLastNameEnglish" :error-message="lastNameEnglishError"/>
      <text-input class="margin-top-m" label-text="מספר תעודת זהות*" autocomplete="id-number" :value="idNumber"
                  :onInput="setSocialIdNumber" inputType="tel" :error-message="socialIdError" />
      <text-input class="margin-top-m" label-text="תאריך לידה*"
                  v-model="dateOfBirth" :onInput="setDateOfBirth" :error-message="dateOfBirthError"/>
    </div>
  </sticky-cta-page>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapState } from 'vuex';
import { isIsraeliIdValid } from '@/rules/validators';
import FlowProgressHeader from './FlowProgressHeader.vue';

const englishCharsRegex = /^[a-zA-Z-'.\s]*$/;

export default {
  name: 'MorePersonalInfo',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    TextInput: BaseUI.V2Components.TextInput,
    SelectionButton: BaseUI.SelectionButton,

  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gender: undefined,
      firstNameEnglish: undefined,
      firstNameEnglishError: '',
      lastNameEnglish: undefined,
      lastNameEnglishError: '',
      idNumber: undefined,
      dateOfBirth: undefined,
      dateOfBirthError: '',
    };
  },
  created() {
    this.gender = this.wizardState.gender ?? undefined;
    this.firstNameEnglish = this.wizardState.firstNameEnglishPiiValue ?? '';
    this.setFirstNameEnglish(this.firstNameEnglish);
    this.lastNameEnglish = this.wizardState.lastNameEnglishPiiValue ?? '';
    this.setLastNameEnglish(this.lastNameEnglish);
    this.idNumber = this.wizardState.idNumberPiiValue ?? '';
    this.setSocialIdNumber(this.idNumber);
    this.dateOfBirth = this.wizardState.dateOfBirthPiiValue ?? '';
    this.setDateOfBirth(this.dateOfBirth);
  },
  computed: {
    ...mapState('session', ['activeMember']),
    nextDisabled() {
      const nameNotInEnglish = !englishCharsRegex.test(this.firstName) || !englishCharsRegex.test(this.lastName);
      return nameNotInEnglish
        || this.firstNameEnglish.length < 2
        || this.lastNameEnglish.length < 2
        || !!this.socialIdError || _.isEmpty(this.idNumber);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
    socialIdError() {
      if (_.isEmpty(this.idNumber)) {
        return '';
      }
      if (this.idNumber.length < 9) {
        return 'תעודת הזהות חייבת להכיל 9 ספרות';
      }
      if (!isIsraeliIdValid(this.idNumber)) {
        return 'תעודת זהות שגויה';
      }
      return '';
    },
  },
  methods: {
    setGender(gender) {
      this.gender = gender;
    },
    setFirstNameEnglish(value) {
      if (!englishCharsRegex.test(value)) {
        this.lastNameError = 'באנגלית בלבד';
      } else {
        this.firstNameError = '';
      }
    },
    setLastNameEnglish(value) {
      if (!englishCharsRegex.test(value)) {
        this.lastNameError = 'באנגלית בלבד';
      } else {
        this.lastNameError = '';
      }
    },
    setSocialIdNumber(value) {
      this.idNumber = value;
    },
    setDateOfBirth(dateOfBirth) {
      if (false && true) {
        this.dateOfBirthError = 'הצטרפות מעל גיל 18';
      } else {
        this.dateOfBirthError = '';
      }
    },
    nextStep() {
      const info = {
        firstNameEnglish: this.firstNameEnglish,
        lastNameEnglish: this.lastNameEnglish,
        gender: this.gender,
        idNumber: this.idNumber,
        birthDate: this.dateOfBirth,
      };
      Segment.trackUserInteraction('Xnes_MorePersonalInfo_NextClicked');
      this.$emit('next', info);
    },
  },
};
</script>
