<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="העלאת תעודה מזהה" :total-steps="6" :current-step="3"/>

      <div class="ri-large-headline">העלאת תעודה מזהה כדי שנוכל לזהות אותך בוודאות</div>
      <div class="ri-large-body margin-bottom-m">עדיף לסיים עם זה עכשיו אבל אפשר גם להעלות אותה אח״כ מול נציג בוואטסאפ</div>

      <selection-button :selected="this.idType === 'legacyId'"
                        @select="selectType('legacyId')"
                        title="תעודת זהות רגילה"
                        :icon-props="{ iconName: 'badge', fill: true, weight: 'bold' }"
                        class="margin-bottom-s"/>

      <selection-button :selected="this.idType === 'biometricId'"
                        @select="selectType('biometricId')"
                        title="תעודת זהות ביומטרית"
                        :icon-props="{ iconName: 'fingerprint', fill: true, weight: 'bold' }"
                        class="margin-bottom-s"/>

      <selection-button :selected="this.idType === 'driversLicense'"
                        @select="selectType('driversLicense')"
                        title="רשיון נהיגה"
                        :icon-props="{ iconName: 'directions_car', fill: true, weight: 'bold' }"/>

    </div>
  </sticky-cta-page>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'IdTypeSelection',
  components: {
    FlowProgressHeader,
    SelectionButton: BaseUI.SelectionButton,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      idType: undefined,
    };
  },
  created() {
    this.idType = this.wizardState.idType ?? undefined;
  },
  computed: {
    nextDisabled() {
      return _.isEmpty(this.idType);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    selectType(value) {
      Segment.trackUserInteraction('Xnes_IdTypeSelection_Selected', { type: value });
      this.idType = value;
    },
    nextStep() {
      const existingSelection = this.wizardState.idDocument?.idType;
      const typeChanged = existingSelection !== this.idType;
      Segment.trackUserInteraction('Xnes_IdTypeSelection_NextClicked', { edited: existingSelection && typeChanged });
      this.$emit('next', {
        idDocument: {
          idType: this.idType,
          idImagePathFront: typeChanged ? '' : this.wizardState.idDocument.idImagePathFront,
          idImagePathBack: typeChanged ? '' : this.wizardState.idDocument.idImagePathBack,
          idImagePath: typeChanged ? '' : this.wizardState.idDocument.idImagePath,
        },
      });
    },
  },
};
</script>
