<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="הצהרות: מצב תעסוקתי" :total-steps="6" :current-step="5"/>
      <div class="ri-large-headline">מה מתאר הכי נכון את המצב התעסוקתי שלך כיום?</div>
      <div class="ri-large-body margin-bottom-m">חובה לבחור אחד לפחות, לכל היותר שניים</div>

      <div>
        <check-box v-model="employment.unemployed" :initially-checked="employment.unemployed"
                   :disabled="!employment.unemployed && maxChecked" label="אני לא מועסק.ת" />
        <check-box v-model="employment.employed" :initially-checked="employment.employed"
                   :disabled="!employment.employed && maxChecked" label="אני שכיר.ה" />
        <check-box v-model="employment.independent" :initially-checked="employment.independent"
                   :disabled="!employment.independent && maxChecked" label="אני בעל.ת עסק / עצמאי.ת" />
        <check-box v-model="employment.employedMgmt" :initially-checked="employment.employedMgmt"
                   :disabled="!employment.employedMgmt && maxChecked" label="אני שכיר.ה בעל.ת שליטה בחברה" />
        <check-box v-model="employment.solider" :initially-checked="employment.solider"
                   :disabled="!employment.solider && maxChecked" label="אני חייל.ת בקבע" />
        <check-box v-model="employment.soliderKeva" :initially-checked="employment.soliderKeva"
                   :disabled="!employment.soliderKeva && maxChecked" label="אני חייל.ת בשרות חובה" />
        <check-box v-model="employment.police" :initially-checked="employment.police"
                   :disabled="!employment.police && maxChecked" label="אני שוטר.ת" />
        <check-box v-model="employment.student" :initially-checked="employment.student"
                   :disabled="!employment.student && maxChecked" label="אני סטודנט.ית" />
        <check-box v-model="employment.pensions" :initially-checked="employment.pensions"
                   :disabled="!employment.pensions && maxChecked" label="אני פנסיונר.ית" />
        <check-box v-model="employment.religiousStudent" :initially-checked="employment.religiousStudent"
                   :disabled="!employment.religiousStudent && maxChecked" label="אני אברך" />
      </div>

    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'Employment',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    CheckBox: BaseUI.CheckBox,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employment: {
        unemployed: false,
        employed: false,
        independent: false,
        employedMgmt: false,
        solider: false,
        soliderKeva: false,
        police: false,
        student: false,
        pensions: false,
        religiousStudent: false,
      },
    };
  },
  created() {
    if (!_.isUndefined(this.wizardState.employment)) {
      this.employment = this.wizardState.employment;
    }
  },
  computed: {
    maxChecked() {
      return _.chain(this.employment).values().sumBy(item => (!item ? 0 : 1)).value() >= 2;
    },
    nextDisabled() {
      return _.chain(this.employment).values().sumBy(item => (!item ? 0 : 1)).value() < 1;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    nextStep() {
      Segment.trackUserInteraction('Xnes_Employment_NextClicked');
      // this.$emit('next', this.employment);
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">

</style>
