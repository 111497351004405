<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="הצהרות: מצב תעסוקתי" :total-steps="6" :current-step="5"/>
      <div class="ri-large-headline">מה טווח ההכנסה החודשית (נטו) שלך? אישית לא כל התא המשפחתי</div>
      <div class="ri-large-body margin-bottom-m">(אולי למה אנחנו שואלים?)</div>
      <radio-button unique-key="income" :items="options" @change="setSelected"/>
    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import RadioButton from '@/pages/responsive-pages/authenticated/navigation-pages/xnes/components/RadioButton.vue';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'Employment',
  components: {
    RadioButton,
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      incomeValue: undefined,
      options: [
        { value: 'l0', label: 'אין הכנסה' },
        { value: 'l1', label: '0 - 7,500 ₪' },
        { value: 'l2', label: '7,500 ₪ - 15,000 ₪' },
        { value: 'l3', label: '15,000 ₪ - 30,000 ₪' },
        { value: 'l4', label: '30,000 ₪ - 60,000 ₪' },
        { value: 'l5', label: 'מעל 60,000 ₪' }],
    };
  },
  created() {
    if (!_.isUndefined(this.wizardState.incomeValue)) {
      this.incomeValue = this.wizardState.incomeValue;
    }
  },
  computed: {
    nextDisabled() {
      return _.isUndefined(this.incomeValue);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setSelected(newValue) {
      this.incomeValue = newValue;
    },
    nextStep() {
      Segment.trackUserInteraction('Xnes_Income_NextClicked');
      // this.$emit('next', this.incomeValue);
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">

</style>
