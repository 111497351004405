<template>
    <div class="xnes-radio-buttons">
      <label class="radio-item" v-for="(option, index) in items" :key="index">
        <input class="radio-button" type="radio" :id="uniqueKey" :value="option.value" v-model="selected"
               @change="$emit('change', selected)"/>
        <span class="custom-radio"></span>
        <span class="margin-right-s">{{option.label}}</span>
      </label>
  </div>

</template>

<script>

export default {
  name: 'RadioButton',
  components: {
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    uniqueKey: {
      type: String,
      required: true,
    },
    initialSelectedValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return { selected: undefined };
  },
  created() {
    if (this.initialSelectedValue) {
      this.selected = this.initialSelectedValue;
    }
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

 .xnes-radio-buttons {
   display: flex;
   flex-direction: column;

   .radio-item:not(:last-child){
     margin-bottom: $ri-spacing-s;
   }

   .radio-item {
     display: flex;
     flex-direction: row;
     align-items: center;
     height: 30px;
   }

   /* Hide the default radio button */
   input[type="radio"] {
     position: absolute;
     opacity: 0;
     width: 0;
     height: 0;
   }

   /* Custom radio button styles */
   .custom-radio {
     display: inline-block;
     height: 25px;
     width: 25px;
     min-width: 25px;
     min-height: 25px;
     border-radius: 50%;
     border: 3px solid $riseup_blue; /* Blue border for both states */
     position: relative;
     cursor: pointer;
     transition: background-color 0.3s;
   }

   /* Add the inner circle for the checked state */
   input[type="radio"]:checked + .custom-radio::after {
     content: '';
     width: 12px; /* Adjust the size of the inner circle */
     height: 12px; /* Adjust the size of the inner circle */
     background-color: $riseup_blue;
     border-radius: 50%;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
   }
 }

</style>
