<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="אזרחות ותושבות מס" :total-steps="6" :current-step="2"/>
      <div class="ri-large-headline">כדי לוודא שאפשר לפתוח לך חשבון מסחר, אנחנו צריכים לדעת את הדברים הבאים:</div>

        <div class="ri-large-body margin-top-m margin-bottom-xs">האם את עובד.ת בבנק או בבית השקעות?</div>
        <div class="flex-row small-buttons">
          <selection-button @select="setWorksInBank(false)"
                            :selected="worksInBank === false"
                            title="לא"
                            :icon-props="{ iconName: 'close', fill: true, weight: 'bold' }"
                            class="margin-bottom-s margin-left-xs"/>
          <selection-button @select="setWorksInBank(true)"
                            :selected="!!worksInBank"
                            title="כן"
                            :icon-props="{ iconName: 'check', fill: true, weight: 'bold' }"
                            class="margin-bottom-s margin-right-xs"/>
        </div>

      <div class="ri-large-body margin-top-m margin-bottom-xs">האם יש לך אזרחות אמריקאית?</div>
      <div class="flex-row small-buttons">
        <selection-button @select="setAmericanCitizenship(false)"
                          :selected="americanCitizenship === false"
                          title="לא"
                          :icon-props="{ iconName: 'close', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-left-xs"/>
        <selection-button @select="setAmericanCitizenship(true)"
                          :selected="!!americanCitizenship"
                          title="כן"
                          :icon-props="{ iconName: 'check', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-right-xs"/>
      </div>

      <div class="ri-large-body margin-top-m margin-bottom-xs">האם יש לך אזרחות נוספת מלבד ישראלית?</div>
      <div class="flex-row small-buttons">
        <selection-button @select="setOtherCitizenship(false)"
                          :selected="otherCitizenship === false"
                          title="לא"
                          :icon-props="{ iconName: 'close', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-left-xs"/>
        <selection-button @select="setOtherCitizenship(true)"
                          :selected="!!otherCitizenship"
                          title="כן"
                          :icon-props="{ iconName: 'check', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-right-xs"/>
      </div>

    </div>
  </sticky-cta-page>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import { mapState } from 'vuex';
import FlowProgressHeader
  from '@/pages/responsive-pages/authenticated/navigation-pages/xnes/join/FlowProgressHeader.vue';

export default {
  name: 'Citizenship',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    SelectionButton: BaseUI.SelectionButton,

  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      worksInBank: undefined,
      americanCitizenship: undefined,
      otherCitizenship: undefined,
    };
  },
  created() {
    this.worksInBank = this.wizardState.worksInBank ?? undefined;
    this.americanCitizenship = this.wizardState.americanCitizenship ?? undefined;
    this.otherCitizenship = this.wizardState.otherCitizenship ?? undefined;
  },
  computed: {
    ...mapState('session', ['activeMember']),
    nextDisabled() {
      return _.isUndefined(this.worksInBank)
          || !!this.worksInBank
          || _.isUndefined(this.americanCitizenship)
          || !!this.americanCitizenship
          || _.isUndefined(this.otherCitizenship);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setWorksInBank(value) {
      this.worksInBank = value;
    },
    setAmericanCitizenship(value) {
      this.americanCitizenship = value;
    },
    setOtherCitizenship(value) {
      this.otherCitizenship = value;
    },
    nextStep() {
      const info = {
        worksInBank: this.worksInBank,
        americanCitizenship: this.americanCitizenship,
        otherCitizenship: this.otherCitizenship,
      };
      Segment.trackUserInteraction('Xnes_Citizenship_NextClicked');
      this.$emit('next', info);
    },
  },
};
</script>
<style scoped lang="scss">

.small-buttons {
  width: 80%;
}
</style>
