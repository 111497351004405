import _ from 'lodash';
import { client } from '../http';

export async function getWizardState() {
  const response = await client.get('/api/xnes/wizard-state');
  return response.data;
}
export async function createWizardState() {
  await client.post('/api/xnes/wizard-state');
}

export async function upsertWizardState(newState) {
  const stateWithoutPiiIds = _.pickBy(newState, (value, key) => !key.endsWith('PiiId') && !key.endsWith('PiiValue'));
  await client.put('/api/xnes/wizard-state', stateWithoutPiiIds);
}

export async function uploadSocialId(idFile) {
  const formData = new FormData();
  formData.append('file', idFile);
  const response = await client.post('/api/xnes/upload-id', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}
