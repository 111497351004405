<template>
  <div class="progress-text">
    שלב {{ currentStep }} מתוך {{ totalSteps }}
    <span class="ri-font-weight-bold"> · {{ currentStepName }}</span>
  </div>
</template>

<script>

export const StepBaseProps = {
  currentStep: {
    type: Number,
    required: true,
  },
  totalSteps: {
    type: Number,
    required: true,
  },
  currentStepName: {
    type: String,
    required: true,
  },
};

export default {
  name: 'FlowProgressHeader',
  props: StepBaseProps,
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.progress-text {
  color: $riseup_blue;
  margin-bottom: $ri-spacing-ml;
}
</style>
