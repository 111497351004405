<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="אזרחות ותושבות מס" :total-steps="6" :current-step="2"/>
      <div class="ri-large-headline">האם יש לך תושבות מס נוספת?</div>
      <div class="ri-large-body margin-bottom-m">כלומר, האם מבוצע על ידך תשלום מיסים במדינת התושבות על פי החוק באותה מדינה</div>

      <div class="flex-row small-buttons">
        <selection-button @select="setPayingTaxesOtherCountries(false)"
                          :selected="payingTaxesOtherCountries === false"
                          title="לא"
                          :icon-props="{ iconName: 'close', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-left-xs"/>
        <selection-button @select="setPayingTaxesOtherCountries(true)"
                          :selected="!!payingTaxesOtherCountries"
                          title="כן"
                          :icon-props="{ iconName: 'check', fill: true, weight: 'bold' }"
                          class="margin-bottom-s margin-right-xs"/>
      </div>

    </div>
  </sticky-cta-page>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import FlowProgressHeader
  from '@/pages/responsive-pages/authenticated/navigation-pages/xnes/join/FlowProgressHeader.vue';

export default {
  name: 'PayingTaxes',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    SelectionButton: BaseUI.SelectionButton,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      payingTaxesOtherCountries: undefined,
    };
  },
  created() {
    this.payingTaxesOtherCountries = this.wizardState.payingTaxesOtherCountries ?? undefined;
  },
  computed: {
    nextDisabled() {
      return _.isUndefined(this.payingTaxesOtherCountries);
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    setPayingTaxesOtherCountries(value) {
      this.payingTaxesOtherCountries = value;
    },
    nextStep() {
      const info = {
        payingTaxesOtherCountries: this.payingTaxesOtherCountries,
      };
      Segment.trackUserInteraction('Xnes_PayingTaxes_NextClicked');
      this.$emit('next', info);
    },
  },
};
</script>
<style scoped lang="scss">

.small-buttons {
  width: 80%;
}
</style>
