<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="הצהרות: עיסוקים נוספים וסטטוס" :total-steps="6" :current-step="5"/>
      <div class="ri-large-headline">האם אחד או יותר מהמשפטים הבאים נכון לגביך?</div>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="statements.none"
                   label="אף אחד מהמשפטים לא נכון לגבי">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="statements.bankruptcy"
                   alignment="top"
                   label="חשבון בנק בבעלותי מעוקל או מוגבל | הוכרזתי כפושט רגל |
                   חברה בבעלותי הוכרזה כחדלת פירעון | סורבתי לפתיחת חשבון או לקבלת שירותים מתאגיד בנקאי / מחבר בורסה אחר">
        </check-box>
      </card>

      <card class="margin-top-ml padding-top-sm">
        <check-box :initially-checked="false"
                   v-model="statements.legalOrInquiry"
                   alignment="top"
                   label="הייתי/אני עדיין מעורב.ת או בהליכים משפטיים או בהליכי חקירה בקשר עם:
מרמה ו/או קבלת שוחד/גניבה/זיוף
בנקים, חברי בורסה או מוסדות פיננסיים
פעילות בניירות ערך או נכסים פיננסים
חוק איסור הלבנת הוך, תש״ס 2000 והצווים מכוחו
... (האחרון שם)">
        </check-box>
      </card>

    </div>
  </sticky-cta-page>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import FlowProgressHeader from './FlowProgressHeader.vue';

export default {
  name: 'Statements',
  components: {
    Card: BaseUI.Card,
    CheckBox: BaseUI.CheckBox,
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statements: {
        none: false,
        bankruptcy: false,
        legalOrInquiry: false,
      },
    };
  },
  created() {
    if (!_.isUndefined(this.wizardState.statements)) {
      this.statements = this.wizardState.statements;
    }
  },
  computed: {
    nextDisabled() {
      return _.chain(this.statements).values().sumBy(item => (!item ? 0 : 1)).value() < 1;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    nextStep() {
      Segment.trackUserInteraction('Xnes_Income_NextClicked');
      // this.$emit('next', this.incomeValue);
      this.$emit('next', {});
    },
  },
};
</script>

<style scoped lang="scss">

</style>
