<template>
  <sticky-cta-page :cta-props="pageCtaProps" @click="nextStep">
    <div class="flex-column flex-1 padding-horizontal-ml padding-vertical-xs">
      <flow-progress-header current-step-name="בחירת חשבון בנק" :total-steps="6" :current-step="4"/>
      <div class="ri-large-headline">איזה חשבון בנק ישמש אותך להעברת וקבלת כספים?</div>
      <div class="ri-large-body margin-bottom-m">אפשר לבחור רק מהחשבונות שמחוברים לרייזאפ</div>
      <dropdown class="margin-top-ml"
                placeholder="בחירת חשבון בנק"
                :firstSelected="selectedBank"
                :options="bankAccountsOptions"
                :on-select="onSelect" />
    </div>
  </sticky-cta-page>
</template>

<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import Dropdown
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/referral-receive-payments/DropdownInput';
import Segment from '@/Segment';
import FlowProgressHeader
  from '@/pages/responsive-pages/authenticated/navigation-pages/xnes/join/FlowProgressHeader.vue';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'SelectBankAccount',
  components: {
    FlowProgressHeader,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    Dropdown,
  },
  props: {
    wizardState: {
      type: Object,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    totalSteps: {
      type: Number,
      required: true,
    },
    ctaProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedBankLabel: undefined,
      selectedBank: undefined,
    };
  },
  created() {
    this.selectedBank = this.wizardState.accountNumberPiiValue
      ? _.find(this.bankAccountsOptions, item => item.label === `${this.wizardState.bankBranchPiiValue}-${this.wizardState.accountNumberPiiValue}`)
      : undefined;
    if (this.selectedBank) {
      this.onSelect(this.selectedBank, true);
    }
  },
  computed: {
    ...mapGetters('credentials', ['connectedBankCredentials']),
    bankAccountDetails() {
      return _.flatMap(this.connectedBankCredentials, bank => {
        const { idNumber } = bank;
        return _.chain(bank.accounts)
          .filter(account => account.sourceType === 'bank')
          .map(account => {
            return {
              ...this.parseBankAccountNumber(account.accountNumberPiiValue),
              idNumber,
              bankAccountNumber: account.accountNumberPiiValue,
              source: account.source,
              bankCode: bank.bankCode.toString(),
            };
          })
          .value();
      });
    },
    bankAccountsOptions() {
      const bankAccountsLabels = _.map(this.bankAccountDetails, account => {
        return {
          label: account.bankAccountNumber,
          selectedLabel: account.bankAccountNumber,
        };
      });

      return bankAccountsLabels;
    },
    selectedBankDetails() {
      const existingBankDetails = _.find(this.bankAccountDetails, { bankAccountNumber: this.selectedBankLabel });
      if (!existingBankDetails) {
        return null;
      }
      return {
        idNumber: { value: existingBankDetails.idNumber, disabled: !!existingBankDetails.idNumber },
        branchNumber: { value: existingBankDetails.branchNumber, disabled: !!existingBankDetails.branchNumber },
        accountNumber: { value: existingBankDetails.accountNumber, disabled: true },
        bankCode: { value: existingBankDetails.bankCode, disabled: true },
      };
    },
    nextDisabled() {
      return !this.selectedBankDetails;
    },
    pageCtaProps() {
      return { ...this.ctaProps, buttonProps: { ...this.ctaProps.buttonProps, disabled: this.nextDisabled } };
    },
  },
  methods: {
    parseBankAccountNumber(bankAccountNumber) {
      const bankAccountNumberParts = bankAccountNumber.split('-');
      return (bankAccountNumberParts.length >= 1)
        ? {
          branchNumber: bankAccountNumberParts[bankAccountNumberParts.length - 2] ?? '',
          accountNumber: bankAccountNumberParts[bankAccountNumberParts.length - 1],
        } : {};
    },
    onSelect(selectedBankAccount, restoredState = false) {
      Segment.trackUserInteraction('Altshuler_SelectBankAccount_Selected', { restoredState });
      this.selectedBankLabel = selectedBankAccount.label;
    },
    nextStep() {
      Segment.trackUserInteraction('Altshuler_SelectBankAccount_NextClicked');
      // eslint-disable-next-line no-unused-vars
      const data = {
        accountNumber: this.selectedBankDetails.accountNumber.value,
        bankBranch: this.selectedBankDetails.branchNumber.value.toString(),
        bankCode: this.selectedBankDetails.bankCode.value,
      };
      // FIXME:
      this.$emit('next', {});
    },
  },
};
</script>
